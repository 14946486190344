/* global $ */
import { defineAsyncComponent } from "vue";

export function registerGlobalComponents(app) {
  // app.component(
  //   "auth-layout",
  //   defineAsyncComponent(() => import("@/layouts/auth"))
  // );

  app.component(
    "default-layout",
    defineAsyncComponent(() => import("@/layouts/default"))
  );

  app.component(
    "home-layout",
    defineAsyncComponent(() => import("@/layouts/home"))
  );
};

export function isScrolledIntoView(elem) {
  var $elem = $(elem);
  var $window = $(window);

  var docViewTop = $window.scrollTop();
  var docViewBottom = docViewTop + $window.height();

  var elemTop = $elem.offset().top;
  // var elemBottom = elemTop + $elem.height();
  // return elemBottom <= docViewBottom && elemTop >= docViewTop;
  return docViewBottom > elemTop + 200;
};

export function conuterNumber(elem, elem2) {
  var isAlreadyRun = false;

  $(window).scroll(function () {
    $(elem).each(function () {
      var bottom_of_object =
        $(this).position().top + $(this).outerHeight() / 2;
      var bottom_of_window = $(window).scrollTop() + $(window).height();
      console.log(bottom_of_object);
      if (bottom_of_window > bottom_of_object + 20) {
        if (!isAlreadyRun) {
          $(elem2).each(function () {
            // Number.prototype.format = function (n, x) {
            //   var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\" : "$") + ")";
            //   return this.toFixed(Math.max(0, ~~n)).replace(
            //     new RegExp(re, "g"),
            //     "$&."
            //   );
            // };
            $(this)
              .prop("Counter", 0)
              .animate(
                {
                  Counter: $(this).text(),
                },
                {
                  duration: 3500,
                  easing: "swing",
                  step: function (now) {
                    $(this).text(Math.ceil(now));
                    // $(this).html(now#);
                  },
                }
              );
          });
        }
        isAlreadyRun = true;
      }
    });
  });

};

export function conuterNumber1(elem, elem2) {
  var isAlreadyRun1 = false;

  $(window).scroll(function () {
    $(elem).each(function () {
      var bottom_of_object2 =
        $(this).position().top + $(this).outerHeight() / 2;
      var bottom_of_window2 = $(window).scrollTop() + $(window).height();

      if (bottom_of_window2 > bottom_of_object2 + 20) {
        if (!isAlreadyRun1) {
          $(elem2).each(function () {
            Number.prototype.format = function (n, x) {
              var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\" : "$") + ")";
              return this.toFixed(Math.max(0, ~~n)).replace(
                new RegExp(re, "g"),
                "$&,"
              );
            };
            console.log('chay 2');
            $(this)
              .prop("Counter", 0)
              .animate(
                {
                  Counter: $(this).text(),
                },
                {
                  duration: 3500,
                  easing: "swing",
                  step: function (now) {
                    $(this).html(now.format());
                  },
                }
              );
          });
        }
        isAlreadyRun1 = true;
      }
    });
  });
};

export function conuterNumber2(elem, elem2) {
  var isAlreadyRun3 = false;

  $(window).scroll(function () {
    $(elem).each(function () {
      var bottom_of_object3 =
        $(this).position().top + $(this).outerHeight() / 2;
      var bottom_of_window3 = $(window).scrollTop() + $(window).height();

      if (bottom_of_window3 > bottom_of_object3 + 20) {
        if (!isAlreadyRun3) {
          $(elem2).each(function () {
            Number.prototype.format = function (n, x) {
              var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\" : "$") + ")";
              return this.toFixed(Math.max(0, ~~n)).replace(
                new RegExp(re, "g"),
                "$&,"
              );
            };
            console.log('chay 2');
            $(this)
              .prop("Counter", 0)
              .animate(
                {
                  Counter: $(this).text(),
                },
                {
                  duration: 3500,
                  easing: "swing",
                  step: function (now) {
                    $(this).html(now.format());
                  },
                }
              );
          });
        }
        isAlreadyRun3 = true;
      }
    });
  });
};

export function conuterNumber3(elem, elem2) {
  $(window).scroll(startCounter);
  function commaSeparateNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
  };

  function startCounter() {
    let scrollY = (window.pageYOffset || document.documentElement.scrollTop) + window.innerHeight;

    if (elem) {
      let divPos = document.querySelector(elem).offsetTop;



      if (scrollY > divPos) {
        $(window).off("scroll", startCounter);

        $(elem2).each(function () {
          var $this = $(this);
          $({
            Counter: 0
          }).animate({
            Counter: $this.text().replace(/,/g, '')
          }, {
            duration: 2000,
            easing: 'swing',
            step: function () {
              $this.text(commaSeparateNumber(Math.floor(this.Counter)));
            },
            complete: function () {
              $this.text(commaSeparateNumber(this.Counter));
              //alert('finished');
            }
          });
        });
      }
    }


  }
};


export function conuterNumber4(elem, elem2) {
  $(window).scroll(startCounter);
  function commaSeparateNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  function startCounter() {
    let scrollY = (window.pageYOffset || document.documentElement.scrollTop) + window.innerHeight;

    if (elem) {
      let divPos = document.querySelector(elem).offsetTop;
      if (scrollY > divPos) {
        $(window).off("scroll", startCounter);

        $(elem2).each(function () {
          var $this = $(this);
          $({
            Counter: 0
          }).animate({
            Counter: $this.text().replace(/,/g, '')
          }, {
            duration: 2000,
            easing: 'swing',
            step: function () {
              $this.text(commaSeparateNumber(Math.floor(this.Counter)));
            },
            complete: function () {
              $this.text(commaSeparateNumber(this.Counter));
              //alert('finished');
            }
          });
        });
      }
    }


  }
};

// export function autoScroll(elem) {
//   // console.log(this.subActive);
//   let ref1 = elem + store.state.subActive;
//   let el = ref[ref1];
//   if (store.state.subActive >= 0) {
//     el.scrollIntoView({
//       behavior: "smooth",
//       block: "start",
//     });
//   }
// }