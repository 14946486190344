<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { computed } from "vue";
import { useRoute } from "vue-router";

import { PUBLIC_LAYOUT } from "@/constants";
export default {
  setup() {
    const route = useRoute();
    console.log(route.meta);
    return {
      layout: computed(() => (route.meta.layout || PUBLIC_LAYOUT)),
    };
  },
};
</script>
