import { createRouter, createWebHistory } from "vue-router";
//Auth guards
// const requireAuth = (to, from, next) => {
//   const user = projectAuth.currentUser;

//   if (!user) next({ name: "Login", params: {} });
//   else next();
// };

const routes = [{
    path: "/",
    name: "Home",
    meta: {
        text: "Amber Hills",
    },
    component: () =>
        import( /* webpackChunkName: "home" */ "../views/index.vue"),
},
{
    path: "/info",
    name: "Info",
    meta: {
        text: "Info",
    },
    component: () =>
        import( /* webpackChunkName: "info" */ "../views/info.vue"),
},
{
    path: "/news",
    name: "News",
    meta: {
        text: "News",
    },
    component: () =>
        import( /* webpackChunkName: "news" */ "../views/news.vue"),
},
{
    path: "/investor",
    name: "Investor",
    meta: {
        text: "Investor",
    },
    component: () =>
        import( /* webpackChunkName: "investor" */ "../views/investor.vue"),
},
{
    path: "/about",
    name: "About",
    meta: {
        text: "About",
    },
    component: () =>
        import( /* webpackChunkName: "about" */ "../views/about.vue"),
},
{
    path: "/why-choose",
    name: "WhyChoose",
    meta: {
        text: "WhyChoose",
    },
    component: () =>
        import( /* webpackChunkName: "about" */ "../views/choose.vue"),
},
{
    path: "/detail/:id",
    name: "Detail",
    meta: {
        text: "Detail",
        layout: "home-layout"
    },
    component: () =>
        import( /* webpackChunkName: "detail" */ "../views/newDetail.vue"),
},
{
    path: "/contact",
    name: "Contact",
    meta: {
        text: "Contact",
        layout: "home-layout"
    },
    component: () =>
        import( /* webpackChunkName: "contact" */ "../views/contact.vue"),
}
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
