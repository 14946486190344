export default {
    methods: {
        trans(string) {
            // Makes a string: core.button.cancel | core.button.created at
            // to: core["button.cancel"] | core["button.created at"]
            const array = string.split('.');
            if (array.length < 2) { return this.$t(string); }

            const first = array.splice(0, 1);
            const key = array.join('.');

            return this.$t(`${first}['${key}']`);
        },
        capitalize(str) {
            if (str) {
                return str.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase());
            }
            return '';
        }
    },
};
