import { createI18n } from 'vue-i18n';
import axios from 'axios';

import setting from '@/services/SettingService';

export const i18n = createI18n({
    locale: 'vi',
    fallbackLocale: 'vi'
})
const loadedLanguages = []

function setI18nLanguage(lang) {
    i18n.locale = lang;
    i18n.global.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export function loadLanguageAsync(lang) {
    if (loadedLanguages.includes(lang)) {
        if (i18n.locale !== lang) setI18nLanguage(lang)
        return Promise.resolve()
    }

    return setting.getAmberHillSetting().then(res => {
        let msgs = res.data
        setI18nLanguage(lang)
        loadedLanguages.push(lang)
    i18n.global.setLocaleMessage(lang, msgs.translations);
    })
}
