import axios from 'axios'
// import store from '@/store/store';
const baseDomain = process.env.VUE_APP_API;
// const language = ['vi', 'en', 'ko'].includes(localStorage.getItem("locale")) ? localStorage.getItem("locale") : 'vi';
// const baseURL = `${baseDomain}/${language}/`;
const baseURL = `${baseDomain}/api/v1/client/`;
const apiClient = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  }
})
// apiClient.interceptors.request.use(function (config) {
//   config.baseURL = baseURL;
//   return config;
// })
// apiClient.interceptors.response.use(function (response) {
//   return response;
// }, function (error) {
//   throw error;
// })

export default apiClient;
